<template>
  <el-dialog
    :visible.sync="visible"
    :title="!dataForm.id ? $t('add') : $t('update')"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmitHandle()"
      label-width="120px"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="合作方">
            <el-select
              v-model="dataForm.appCode"
              placeholder="合作方"
              label="appDesc"
              :clearable="true"
              style="width: 100%"
            >
              <el-option
                v-for="item in listByScope"
                :key="item.appCode"
                :label="item.appDesc"
                :value="item.appCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="packageId" :label="$t('rightsCard.packageName')">
            <el-select
              v-model="dataForm.packageId"
              :placeholder="$t('rightsCard.packageName')"
              label="packageName"
              filterable
            >
              <el-option
                v-for="item in packageList"
                :key="item.id"
                :label="getLast4(item.packageName, item.id)"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item prop="count" :label="$t('rightsCard.count')">
            <el-input
              v-model="dataForm.count"
              :placeholder="$t('rightsCard.count')"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-form-item prop="batchDesc" :label="$t('rightsCard.batchDesc')">
            <el-input
              v-model="dataForm.batchDesc"
              :placeholder="$t('rightsCard.batchDesc')"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- 激活限制 -->
      <el-row>
        <el-col :span="24">
          <el-form-item
            prop="appCodeLimit"
            :label="$t('rightsCard.appCodeChannelLimit')"
          >
            <el-select
              v-model="dataForm.appCodeLimit"
              :placeholder="$t('rightsCard.appCodeChannelLimit')"
              label="appCodeLimit"
            >
              <el-option
                v-for="item in codeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item prop="whiteListLimitFile" label="激活白名单限制">
            <el-upload
              name="file"
              :action="url"
              :file-list="fileList"
              drag
              :on-success="successHandle"
              class="text-center"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text" v-html="$t('upload.text')"></div>
              <div @click="download" class="el-upload__text">
                <em>下载模板</em>
              </div>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button
        type="primary"
        @click="dataFormSubmitHandle()"
        :loading="loading"
        loading-type="spinner"
        >{{ $t('confirm') }}</el-button
      >
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce';
import mixinViewModule from '@/mixins/view-module';
import Import from '@/views/modules/demo/excel-import';
const urls = {
  getCode: '/sysapp/list', // 获取应用code
  download: '/health/cardbatch/exportWhiteListTemplate',
};
export default {
  mixins: [mixinViewModule],
  components: {
    Import,
  },
  data() {
    return {
      initType: '1',
      listByScope: [],
      mixinViewModuleOptions: {
        createdIsNeed: false,
      },
      visible: false,
      dataForm: {
        id: '',
        packageId: '',
        count: '',
        batchDesc: '',
        appCodeLimit: '',
        whiteListLimitFile: '',
      },
      statusOptions: [
        {
          value: 3,
          label: ' 碧惠一生A',
        },
        {
          value: 2,
          label: '碧惠一生',
        },
      ],
      packageList: [],
      loading: false,
      importVisible: false,
      codeList: [],
      url: `${
        window.SITE_CONFIG['apiURL']
      }/sys/oss/upload?token=${sessionStorage.getItem('token')}`,
      fileList: [],
    };
  },
  watch: {
    'dataForm.appCode': {
      handler: function (val) {
        if (this.initType == '2') this.dataForm.packageId = '';
        this.getPackageList();
      },
    },
  },

  computed: {
    appCode() {
      return this.packageList.find((i) => i.id == this.dataForm.packageId)
        ?.appCode;
    },
    dataRule() {
      return {
        packageId: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
        count: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
        batchDesc: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
      };
    },
  },
  created() {
    this.getCodeList();
  },
  methods: {
    // 上传之前
    // beforeUploadHandle(file) {
    //     this.fileList = [];
    // },
    // 上传成功
    successHandle(res, file, fileList) {
      if (res.code !== 0) {
        return this.$message.error(res.msg);
      }
      this.fileList = [fileList[fileList.length - 1]];
      this.$message({
        message: this.$t('prompt.success'),
        type: 'success',
        duration: 500,
        onClose: () => {
          // this.visible = false;
          // this.$emit('refreshDataList');
        },
      });
    },
    download() {
      window.location.href = `${window.SITE_CONFIG['apiURL']}${urls.download}`;
      // this.$http.get(urls.download);
    },

    getLast4(str, id) {
      return str + ' ' + `(${String(id).substring(id.length - 4)})`;
    },
    async getCodeList() {
      const res = await this.$http.get(urls.getCode);

      const resData = res.data.data || [];
      this.codeList = resData.map((item) => {
        return {
          label: item.appDesc,
          value: item.appCode,
        };
      });
    },
    init() {
      this.initType = '1';
      this.visible = true;
      this.fileList = [];
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        // this.getPackageList();
        this.getListByScope();
      });
    },
    getListByScope() {
      this.$http
        .get('/sysapp/listByScope')
        .then((res) => {
          console.log(res, 'listByScope');
          if (res.data.code === 0) {
            this.listByScope = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPackageList() {
      this.$http
        .get('/health/package/listByScope', {
          params: {
            appCode: this.dataForm.appCode,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.code === 0) {
            this.packageList = res.data.data;
            this.initType = '2';
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 表单提交
    dataFormSubmitHandle: debounce(
      function () {
        this.$refs['dataForm'].validate((valid) => {
          if (!valid) {
            return false;
          }
          if (this.dataForm.count && this.dataForm.count <= 0) {
            this.$message.error('权益卡发行数量必须为大于0的正整数!');
            return false;
          }
          if (this.fileList.length) {
            this.dataForm.whiteListLimitFile =
              this.fileList[0].response.data.src;
          }
          this.loading = true;
          this.$http
            .post('/health/cardbatch/save', this.dataForm)
            .then(({ data: res }) => {
              this.loading = false;
              if (res.code !== 0) {
                return this.$message.error(res.msg);
              }
              this.$bus.emit('card_list_refresh', {});
              this.$message({
                message: this.$t('prompt.success'),
                type: 'success',
                duration: 500,
                onClose: () => {
                  this.visible = false;
                  this.$emit('refreshDataList');
                },
              });
            })
            .catch(() => {});
        });
      },
      1000,
      { leading: true, trailing: false }
    ),
  },
};
</script>
