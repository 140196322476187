<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="healthRifhts">
      <el-form
        :inline="true"
        :model="dataForm"
        @keyup.enter.native="getDataList()"
      >
        <el-form-item>
          <el-input
            v-model="dataForm.packageId"
            :placeholder="$t('rightsCard.packageId')"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="dataForm.packageName"
            :placeholder="$t('rightsCard.packageName')"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="dataForm.appCode"
            placeholder="合作方"
            label="appDesc"
            :clearable="true"
            style="width: 100%"
          >
            <el-option
              v-for="item in listByScope"
              :key="item.appCode"
              :label="item.appDesc"
              :value="item.appCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()">{{ $t('query') }}</el-button>
        </el-form-item>
        <el-form-item>
          <el-button
            v-if="$hasPermission('sys:rightsCard:add')"
            type="primary"
            style="margin-left: 20px"
            @click="addOrUpdateHandle()"
            >{{ $t('add') }}</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        v-loading="dataListLoading"
        :data="dataList"
        border
        @selection-change="dataListSelectionChangeHandle"
        @sort-change="dataListSortChangeHandle"
        style="width: 100%"
      >
        <!-- <el-table-column
          type="selection"
          header-align="center"
          align="center"
          width="50"
        ></el-table-column> -->
        <el-table-column
          prop="id"
          :label="$t('cardList.batchId')"
          header-align="center"
          align="center"
        >
        </el-table-column>

        <el-table-column
          prop="packageId"
          :label="$t('rightsCard.packageId')"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="packageCode"
          :label="$t('package.packageCode')"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="packageName"
          :label="$t('rightsCard.packageName')"
          header-align="center"
          align="center"
        >
        </el-table-column>

        <el-table-column
          prop="appCode"
          label="合作方"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="appCodeLimit"
          :label="$t('rightsCard.appCodeLimit')"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="count"
          :label="$t('rightsCard.count')"
          header-align="center"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="batchDesc"
          :label="$t('rightsCard.batchDesc')"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="createDate"
          :label="$t('rightsCard.createDate')"
          sortable="custom"
          header-align="center"
          align="center"
          width="180"
        ></el-table-column>
        <el-table-column
          prop="creatorName"
          :label="$t('rightsCard.creator')"
          header-align="center"
          align="center"
          width="180"
        ></el-table-column>
        <el-table-column
          :label="$t('handle')"
          fixed="right"
          header-align="center"
          align="center"
          width="250"
        >
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.whiteListLimitFile"
              type="text"
              size="small"
              @click="download(scope.row.whiteListLimitFile)"
            >
              下载</el-button
            >
            <el-button
              v-if="$hasPermission('sys:rightsCard:export')"
              type="text"
              size="small"
              @click="exportHandle(scope.row.id)"
              >{{ $t('export') }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle"
      >
      </el-pagination>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module';
import AddOrUpdate from './rightsCard-add-or-update';
export default {
  mixins: [mixinViewModule],
  data() {
    return {
      listByScope: [],
      mixinViewModuleOptions: {
        getDataListURL: '/health/cardbatch/page',
        getDataListIsPage: true,
        exportURL: '/health/cardbatch/export',
      },
      dataForm: {
        packageId: '',
        packageName: '',
      },
      statusArray: [
        {
          value: 0,
          text: '禁用',
        },
        {
          value: 1,
          text: '正常',
        },
      ],
      packageList: [],
    };
  },
  methods: {
    getListByScope() {
      this.$http
        .get('/sysapp/listByScope')
        .then((res) => {
          console.log(res, 'listByScope');
          if (res.data.code === 0) {
            this.listByScope = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    download(url) {
      window.location.href = url;
    },
    computedPackageName(val) {
      let name = '';
      if (this.packageList.length > 0) {
        let packageItem = this.packageList.find((item) => {
          return item.id == val;
        });
        name = packageItem.packageName;
      }
      return name;
    },
    getPackageList() {
      this.$http
        .get('/health/package/list')
        .then((res) => {
          if (res.data.code === 0) {
            this.packageList = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  components: {
    AddOrUpdate,
  },
  created() {
    this.getPackageList();
    this.getListByScope();
  },
};
</script>
